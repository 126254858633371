import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../src/pages/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.scss";
import DefaultLayout from "./layout/DefaultLayout";
import ErrorBoundary from "../src/ErrorBoundary.jsx";
import linearImage from "./assets/images/linear.svg";
import { addDataToKeycloakRedirectURI, checkCurrentUserSession, isSessionValid } from "./helpers/helper";
const QRCode = React.lazy(() => import('./pages/Public/QRCode.js'));
const PublicPortal = React.lazy(() => import("./pages/Public/index"));
const WebView = React.lazy(() => import("./pages/WebView/index"));
const CandidatePortal = React.lazy(() => import("./pages/Private/index"));
const JobList = React.lazy(() => import("./pages/Private/Jobs/FindJobs"));
const JobDetails = React.lazy(() => import("./pages/Private/Jobs/JobDetails"));
import Accessibility from "../src/Components/Accessibility";
import Chatbot from '../src/pages/chatbot/index.js';
const ViewPublicProfile = React.lazy(() =>
  import("./pages/Private/Profile/ViewPublicProfile")
);
const HomePage = React.lazy(() => import("./pages/Public/Home"));
const HealthcareHome = React.lazy(() =>
  import("./pages/Public/HealthcareHome")
);
const FindJobDetails = React.lazy(() =>
  import("./pages/Private/Jobs/FindJobDetails")
);
const UploadPictchVideo = React.lazy(() =>
  import("./pages/Private/Jobs/UploadPictchVideo")
);
const NotFound = React.lazy(() => import("../src/PageNotFound.jsx"));


import ReactGA from "react-ga";
import { AppContext } from "./contexts/context";
import { useDispatch, useSelector } from "react-redux";
import { getAppOriginRecode } from "./redux/appOriginReducer";
const TRACKING_ID = process.env.REACT_APP_BEANBAG_ANALYTICS_TRACKING_ID; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
import KeycloakService from "./keycloak";
import ApiCalls from "./api/customAxios.js";
import { setPlatformTheme } from "./helpers/helper-data.js";
import { getPageNotFoundRecode } from "./redux/pageNotFoundReducer.js";
import CookieConsent from "./cookie.js";

document.addEventListener(
  "mousedown",
  function (event) {
    if (event.detail > 1) {
      event.preventDefault();
    }
  },
  false
);
document.addEventListener('mousedown', function (e) {
  toast?.dismiss();
});
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const url = window.location.pathname.split("/")[1];
  const poolId = new URLSearchParams(location.search).get("pool_id") ? new URLSearchParams(location.search).get("pool_id") : new URLSearchParams(location.search).get("poolId");
  const [candidateDraftChecklistsLength, setCandidateDraftChecklistsLength] =
    React.useState(0);
  const [candidatePendingInterview, setCandidaPendingInterview] =
    React.useState(0);
  const dispatch = useDispatch();
  const appOrigin = useSelector((state) => state.location.origin);
  const pageNotFoundError = useSelector((state) => state.pageNotFound.error);
  const cssVariables = {
    "--primary-color": "#055094",
    "--secondary-color": "#EAF4FF",
    "--hover-button-color": "#055094",
    "--hover-button-text-color": "#FFFFFF",
    "--heading-text-color": "#101828",
    "--main-text-color": "#055094",
    "--list-hover-color": "#F9FAFB",
    "--font-family": "Lato",
    "--font-size-10": "12px",
    "--font-size-12": "14px",
    "--font-size-14": "16px",
    "--font-size-16": "18px",
    "--font-size-18": "20px",
    "--font-size-20": "24px",
    "--font-size-24": "26px",
    "--font-size-28": "30px",
    "--font-size-36": "38px",
    "--gray6": "#344054",
    "--gray7": "#344054",
    "--gray8": "#1D2939",
    "--gray9": "#1D2939",
    "--gray10": "#1D2939",
    "--gray11": "#1D2939",
    "--toast-image-height": "25px",
  };
  const getJobIdFromUrl = () => {
    const path = location.pathname;
    const jobIdMatch = path.match(/\/job\/([a-f0-9\-]+)/);
    return jobIdMatch ? jobIdMatch[1] : null;
  };
  React.useEffect( async () => {
    const publicJobDetailsUrl = `${process.env.REACT_APP_API_URL.replace(
      "api/v1/",
      "public/api/v1/"
    )}enterprises`;
    if (new URLSearchParams(location.search).get("utm_source")) {
      const jobId = getJobIdFromUrl();
     await ApiCalls.get(`${publicJobDetailsUrl}/job/${jobId}`)
        .then((response) => {
          let domain = response.enterprisePublicDto.validDomainNames?.includes(",") ? response.enterprisePublicDto.validDomainNames?.split(",")[0] : response.enterprisePublicDto.validDomainNames;
          localStorage.setItem("domain", domain);
        });
    };
  }, [new URLSearchParams(location.search).get("utm_source")]);
  React.useEffect(() => {
    //pool_id is from career portal
    if (new URLSearchParams(location.search).get("pool_id")) {
      localStorage.setItem("pool_id", new URLSearchParams(location.search).get("pool_id"));
      localStorage.setItem("domain", new URLSearchParams(location.search).get("domain"));
    };
  }, [new URLSearchParams(location.search).get("pool_id")]);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    //poolId is from talent pool referral
    if (urlParams.has('referralDetails') && urlParams.has('poolId')) {
      const decodedString = atob(urlParams.get('referralDetails'));
      localStorage.setItem("referralDetails", decodedString);
      localStorage.setItem("poolId", urlParams.get('poolId'));
      localStorage.setItem("domain", JSON.parse(decodedString)?.validDomainName);
    }
  }, [new URLSearchParams(location.search).get("referralDetails")]);
  React.useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    if ("healthcare" === process.env.REACT_APP_ORIGIN) {
      dispatch(getAppOriginRecode("H"));
      document.documentElement.style.setProperty(
        "--primary-color",
        "#45AC8B",
        "important"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#F0FFFA",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-header",
        "14px",
        "important"
      );
      if (localStorage.getItem("token") == null) {
        document.documentElement.style.setProperty(
          "--dashboard-color",
          "linear-gradient(92.14deg, var(--primary-color) 44.94%, var(--secondary-color) 157.15%)"
        );
      }
    } else if ("neurodiversify" === process.env.REACT_APP_ORIGIN) {
      Object.keys(cssVariables).forEach(function(key, index) {
        document.documentElement.style.setProperty(key, cssVariables[key], "important");
      });
      if (localStorage.getItem("token") == null) {
        document.documentElement.style.setProperty(
          "--dashboard-color",
          "var(--primary-color)"
        );
      };
      document.documentElement.style.setProperty(
        "--background-image",
        `url(${linearImage})`,
        "important"
      );
      dispatch(getAppOriginRecode("N"));
    } else {
      dispatch(getAppOriginRecode("D"));
      if (localStorage.getItem("token") == null) {
        document.documentElement.style.setProperty(
          "--dashboard-color",
          "linear-gradient(92.14deg, var(--primary-color) 44.94%, var(--secondary-color) 157.15%)"
        );
      }
    }
    console.log(appOrigin);
  }, []);
  React.useEffect(() => {
    if (
      location.hash.includes("EMAIL_NOT_FOUND_EXCEPTION") ||
      location.hash.includes("NAME_NOT_FOUND") ||
      location.search.includes("EMAIL_NOT_FOUND_EXCEPTION") ||
      location.search.includes("NAME_NOT_FOUND")
      // location.search.includes("EMAIl_ALREADY_EXISTS")
    ) {
      navigate("../signup", { state: "EMAIL_NOT_FOUND_EXCEPTION" });
    }
  }, [location.hash, location.search]);
  React.useEffect(() => {
    if (pageNotFoundError) {
      dispatch(getPageNotFoundRecode(false));
    }
    if (location.pathname === '/candidate/' || location.pathname === '/candidate') {
      navigate('/candidate/dashboard');
    }
  }, [location, navigate]);
  return (
    <div>
      {(url != "webview" && !location.pathname.includes("/candidate/create-profile")) && <Accessibility />}
      {!location.pathname.includes("/create-profile") &&
        !location.pathname.includes("termsofuse") &&
        !location.pathname.includes("privacypolicy") &&
        !location.pathname.includes("ukprivacypolicy") &&
        !location.pathname.includes("cookiepolicy") && <Chatbot />}
      <React.Suspense
        fallback={
          <>
            <Loader />
          </>
        }
      >
       <CookieConsent/>
        <ErrorBoundary>
          <AppContext.Provider
            value={{
              candidateDraftChecklistsLength,
              setCandidateDraftChecklistsLength,
              candidatePendingInterview,
              setCandidaPendingInterview,
            }}
          >
            <Routes>
              <Route
                path="/*"
                element={
                  <AuthGuardCandidateRedirect redirectTo="/candidate/dashboard">
                    <PublicPortal />
                  </AuthGuardCandidateRedirect>
                }
              />
              <Route
                path="/candidate/public/:candidateKey"
                element={
                  <React.Suspense fallback="">
                    <DefaultLayout isDisplay={true} />
                    <br />
                    <ViewPublicProfile />
                  </React.Suspense>
                }
              />
              <Route
                path="/home"
                element={
                  <React.Suspense fallback="">
                    {appOrigin === "D" || appOrigin === "N" ? (
                      <>
                        <DefaultLayout isDisplay={true} />
                        <br />
                        <HomePage />
                      </>
                    ) : (
                      <>
                        <HealthcareHome />
                      </>
                    )}
                  </React.Suspense>
                }
              />
              <Route path="/job-list" element={<JobListNavigation />} />
              {/* <Route
                path="/job-list"
                element={
                  <React.Suspense fallback="">
                    <DefaultLayout isDisplay={true} />
                    <br />
                    <JobList />
                  </React.Suspense>
                }
              /> */}
              <Route
                path="/qr"
                element={
                  <React.Suspense fallback="">
                    <QRCode />
                  </React.Suspense>
                }
              />
              <Route
                path="/job/:id/:referrerId?"
                element={pageNotFoundError ?
                  <NotFound isAuthenticated = {false}/>
                  :
                  <React.Suspense fallback="">
                    <FindJobDetails />
                  </React.Suspense>
                }
              />
              {(poolId != null && KeycloakService.isLoggedIn() == false) ? <Route
                path="candidate/*"
                element={
                  <RequireAuthForExclusive redirectTo="/signin">
                    <CandidatePortal />
                  </RequireAuthForExclusive>
                }
              /> : <Route
                path="candidate/*"
                element={
                  <RequireAuth redirectTo="/signin">
                    <CandidatePortal />
                  </RequireAuth>
                }
              />}
              <Route
                path="/upload-video"
                element={
                  <React.Suspense fallback="">
                    <UploadPictchVideo />
                  </React.Suspense>
                }
              />
              <Route path="webview/*" element={<WebView />} />
              <Route path="*" element={<NotFound  isAuthenticated = {false}/>} />
            </Routes>
          </AppContext.Provider>
        </ErrorBoundary>
        <ToastContainer
          hideProgressBar
          position="top-right"
          icon={true}
          autoClose={15000}
        />
      </React.Suspense>
    </div>
  );
}

export default App;

function RequireAuth({ children, redirectTo }) {
  const location = useLocation();
  console.log("location", location);
  const search = location.search;
  const path = location.pathname;
  // isSessionValid();
  let isAuthenticated = KeycloakService.isLoggedIn();
  console.log("isAuthenticated", isAuthenticated);
  const isEmailVerified = localStorage.getItem("email_verified") === "true";
  const titleName = new URLSearchParams(search).get("name");
  if (
    !isAuthenticated &&
    location.pathname.includes("candidate/job-details/")
  ) {
    localStorage.setItem("jobRedirectUrl", window.location.href);
  } else if (
    !isAuthenticated &&
    location.pathname.includes("candidate")
  ) {
    localStorage.setItem("redirect_uri", window.location.href);
  }
  if (!titleName) {
    const event = new Event("clearSearchBar");
    document.dispatchEvent(event);
  }
  if (path.includes("candidate/public/")) {
    return children;
  }
  if (!isAuthenticated) {
    KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`) });
    return (<div></div>);
  }

  return children;
}
function RequireAuthForExclusive({ children, redirectTo }) {
  const location = useLocation();
  const search = location.search;
  let isAuthenticated = KeycloakService.isLoggedIn();
  //showing whitelableling for the candidates from career portal and referral
  if (!isAuthenticated && (new URLSearchParams(search).get("pool_id") != null || new URLSearchParams(search).get("poolId") != null)) {
    try {
      const urlParams = new URLSearchParams(search);
      let exclusiveReferral = urlParams.get("referralDetails") ? JSON.parse(atob(urlParams.get("referralDetails"))) : null;
      const publicEnterpriseDetailsUrl = `${process.env.REACT_APP_API_URL.replace(
        "api/v1/",
        "public/api/v1/"
      )}candidates/enterprise/details/${urlParams.get("domain") ? urlParams.get("domain") : exclusiveReferral?.validDomainName}`;
      ApiCalls.get(publicEnterpriseDetailsUrl)
        .then(async (res) => {
          localStorage.setItem("sourceEnterpriseId", res.enterpriseId);
          localStorage.setItem("domainEnterpriseId", res.enterpriseId);
          let pool = { "pool_id": new URLSearchParams(search).get("pool_id"), "id": res.enterpriseId, "ename": res.establishmentName };
          localStorage.setItem("poolFromPortal", JSON.stringify(pool));
          // if (res.primaryColor != null) {
              // localStorage.setItem("theme_obj", JSON.stringify({ "p_color": res.primaryColor, "s_color": res.secondaryColor }));
              res.exclusiveCandidateTheme ? setPlatformTheme(res.exclusiveCandidateTheme, process.env.REACT_APP_ORIGIN) : setPlatformTheme(res.theme, process.env.REACT_APP_ORIGIN);
            // };
          if (res.exclusiveCandidateLogoFileSignedUrl)  {
            localStorage.setItem("domainNavBarLogoSignedUrl", res.exclusiveCandidateLogoFileSignedUrl);
          } else if (res.navBarLogoSignedUrl) {
            localStorage.setItem("domainNavBarLogoSignedUrl", res.navBarLogoSignedUrl);
          }
          if (res.careerPortalFavIcon) {
            localStorage.setItem("careerPortalFavIcon", res.careerPortalFavIcon);
          }
          KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`) });
          return (<div></div>);
        });
    } catch (err) { }
  }
  if (!isAuthenticated && new URLSearchParams(search).get("poolId") != null) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('referralDetails')) {
      const decodedString = atob(urlParams.get('referralDetails'));
      // const data = JSON.parse(decodedString);
      localStorage.setItem("referralDetails", decodedString);
      localStorage.setItem("poolId", urlParams.get('poolId'));
      localStorage.setItem("domain", JSON.parse(decodedString)?.validDomainName);
    }
  }
  return (<div></div>);
}
function AuthGuardCandidateRedirect({ children, redirectTo }) {
  const location = useLocation();
  const path = location.pathname;
  // isSessionValid();
  let queryParams = new URLSearchParams(window.location.search);
  let emailQP = queryParams.get('email');

  if (path.includes('/signin')) {
    KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/dashboard`), loginHint: emailQP });
    return (<div></div>);
  }

  if (path.includes('/signup')) {
    //if we come from exclusive candidate flow, first we are calling api for exclusive theme than it will redirect to signup page
    let exclusiveReferral = queryParams.get("referralDetails") ? JSON.parse(atob(queryParams.get("referralDetails"))) : null;//referred candidate should be exclusive
    if (queryParams.get("paramsKey") || exclusiveReferral?.validDomainName) {
      try {
        let domain = queryParams.get("paramsKey") ? JSON.parse(atob(queryParams.get("paramsKey")))?.validDomainNames : exclusiveReferral?.validDomainName;
        localStorage.setItem("domain", domain);
        const publicEnterpriseDetailsUrl = `${process.env.REACT_APP_API_URL.replace(
          "api/v1/",
          "public/api/v1/"
        )}candidates/enterprise/details/${domain}`;
        ApiCalls.get(publicEnterpriseDetailsUrl)
          .then((res) => {
            localStorage.setItem("sourceEnterpriseId", res.enterpriseId);
            localStorage.setItem("domainEnterpriseId", res.enterpriseId);
            res.exclusiveCandidateTheme ? setPlatformTheme(res.exclusiveCandidateTheme, process.env.REACT_APP_ORIGIN) : setPlatformTheme(res.theme, process.env.REACT_APP_ORIGIN);
            if (res.exclusiveCandidateLogoFileSignedUrl) {
              localStorage.setItem("domainNavBarLogoSignedUrl", res.exclusiveCandidateLogoFileSignedUrl);
            } else if (res.navBarLogoSignedUrl)  {
              localStorage.setItem("domainNavBarLogoSignedUrl", res.navBarLogoSignedUrl);
            }
            if (res.careerPortalFavIcon) {
              localStorage.setItem("careerPortalFavIcon", res.careerPortalFavIcon);
            }
            if (KeycloakService.isLoggedIn()) {
              window.location.href = `${window.location.origin}/candidate/create-profile`;
            } else {
              KeycloakService.doRegisteration({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/create-profile`), loginHint: emailQP });
            }
          });
      } catch (err) { }
    } else {
      if (KeycloakService.isLoggedIn()) {
        window.location.href = `${window.location.origin}/candidate/create-profile`;
      } else {
        KeycloakService.doRegisteration({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/create-profile`), loginHint: emailQP });
      }
    }
    return (<div></div>);
  }

  if (path.includes('/forgotpassword')) {
    KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/dashboard`), loginHint: emailQP });
    return (<div></div>);
  }

  let isAuthenticated = KeycloakService.isLoggedIn();
  if (!path.includes("candidate")) {
    return children;
  }

  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}
function JobListNavigation() {
  const location = useLocation();
  const { search } = location;

  const targetPath = KeycloakService.isLoggedIn()
    ? "/candidate/find-jobs"
    : "/job-list";

  if (KeycloakService.isLoggedIn()) {
    return <Navigate to={targetPath + search} replace />;
  }

  return (
    <React.Suspense fallback="">
      <DefaultLayout isDisplay={true} />
      <br />
      <JobList />
    </React.Suspense>
  );
}
